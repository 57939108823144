<template>
  <section>
    <div class="content-header">
      <h2>Facilitating ‘Dialogues Across Difference’</h2>
    </div>
    <div class="content-wrapper">
      <p>Creating a deep sense of belonging and learning from difference requires interpersonal connection and conversation across sociodemographic groups.</p>
      <p>The book, <em><a href="https://muse.jhu.edu/book/22179" target="_blank">Dialogue across difference: Practice, theory, and research on intergroup dialogue</a></em> explores research and tangible strategies for facilitating dialogues across difference within your classroom community.</p>

    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
